import React from 'react';
import { FcGoogle } from 'react-icons/fc';
import { FaVk } from 'react-icons/fa';
import { FaTelegram } from 'react-icons/fa';
import { useAuth } from '../../contexts/AuthContext';

const SocialLoginButtons = () => {
  const { signInWithGoogle, signInWithVK, signInWithTelegram } = useAuth();

  const handleTelegramLogin = () => {
    if (window.Telegram && window.Telegram.Login) {
      window.Telegram.Login.auth(
        { bot_id: process.env.TELEGRAM_BOT_ID },
        (data) => {
          if (data) {
            signInWithTelegram(data);
          }
        }
      );
    } else {
      console.error('Telegram login widget not available');
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <button
        onClick={signInWithGoogle}
        className="flex items-center justify-center gap-2 px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <FcGoogle className="w-5 h-5" />
        Continue with Google
      </button>

      <button
        onClick={signInWithVK}
        className="flex items-center justify-center gap-2 px-4 py-2 text-white bg-[#4C75A3] rounded-lg shadow-sm hover:bg-[#3B5998] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#4C75A3]"
      >
        <FaVk className="w-5 h-5" />
        Continue with VK
      </button>

      <button
        onClick={handleTelegramLogin}
        className="flex items-center justify-center gap-2 px-4 py-2 text-white bg-[#0088cc] rounded-lg shadow-sm hover:bg-[#0077b5] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0088cc]"
      >
        <FaTelegram className="w-5 h-5" />
        Continue with Telegram
      </button>
    </div>
  );
};

export default SocialLoginButtons;
