import React, { createContext, useContext, useState, useEffect } from 'react'
import axios from 'axios'

const AuthContext = createContext({})

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    checkUser()
  }, [])

  const checkUser = async () => {
    try {
      const token = localStorage.getItem('token')
      if (token) {
        const response = await axios.get('/api/auth/me', {
          headers: { Authorization: `Bearer ${token}` }
        })
        setUser(response.data)
      }
    } catch (error) {
      console.error('Error checking user:', error)
      setUser(null)
    } finally {
      setLoading(false)
    }
  }

  const signInWithGoogle = async () => {
    try {
      window.location.href = '/api/auth/google'
    } catch (error) {
      console.error('Error signing in with Google:', error)
      throw error
    }
  }

  const signInWithVK = async () => {
    try {
      window.location.href = '/api/auth/vk'
    } catch (error) {
      console.error('Error signing in with VK:', error)
      throw error
    }
  }

  const signInWithTelegram = async (telegramData) => {
    try {
      const response = await axios.post('/api/auth/telegram', telegramData)
      const { token } = response.data
      localStorage.setItem('token', token)
      await checkUser()
    } catch (error) {
      console.error('Error signing in with Telegram:', error)
      throw error
    }
  }

  const signInWithEmail = async ({ email, password }) => {
    try {
      const response = await axios.post('/api/auth/login', { email, password })
      const { token } = response.data
      localStorage.setItem('token', token)
      await checkUser()
    } catch (error) {
      console.error('Error signing in with email:', error)
      throw error
    }
  }

  const signUp = async ({ email, password }) => {
    try {
      const response = await axios.post('/api/auth/register', { email, password })
      const { token } = response.data
      localStorage.setItem('token', token)
      await checkUser()
    } catch (error) {
      console.error('Error signing up:', error)
      throw error
    }
  }

  const signOut = async () => {
    try {
      await axios.post('/api/auth/logout')
      localStorage.removeItem('token')
      setUser(null)
    } catch (error) {
      console.error('Error signing out:', error)
      throw error
    }
  }

  const value = {
    user,
    loading,
    signInWithGoogle,
    signInWithVK,
    signInWithTelegram,
    signInWithEmail,
    signUp,
    signOut
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
